<template>
  <div class="warpBox">
    <div class="banner">
      <img src="../../images/home-banner.webp" />
    </div>
    <img src="../../images/conpaly.webp" alt="公司介绍" />
    <img src="../../images/team.webp" alt="团队" />
    <img src="../../images/advantage.webp" alt="优势" />
    <div class="content">
      <div class="contentBox center">
        <div class="top">
          <div class="right">
            <el-form
              label-position="right"
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
            >
              <el-form-item label="联系方式：">
                <span class="colorF5C">020-87538546</span>
              </el-form-item>
              <el-form-item label="公司地址：">
                <span class="colorF5C">广州番禺万达互联网创新集聚区</span>
              </el-form-item>
              <el-form-item class="inputBox" prop="name">
                <el-input
                  @focus="focus(1)"
                  v-model="ruleForm.name"
                  class="input"
                  placeholder="您的名字"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item class="inputBox" prop="wechat">
                <el-input
                  @focus="focus(2)"
                  v-model="ruleForm.wechat"
                  class="input"
                  placeholder="您的微信（选填）"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item class="inputBox" prop="phone">
                <el-input
                  @focus="focus(3)"
                  v-model="ruleForm.phone"
                  class="input"
                  placeholder="您的固话/手机"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item class="inputBox" prop="demand">
                <el-input
                  @focus="focus(4)"
                  v-model="ruleForm.demand"
                  maxlength="300"
                  class="input"
                  resize="none"
                  type="textarea"
                  placeholder="填写您的需求"
                  autocomplete="off"
                ></el-input>
                <span style="padding-left: 10px"
                  >{{ ruleForm.demand ? ruleForm.demand.length : 0 }}/300</span
                >
              </el-form-item>
              <el-form-item>
                <el-button
                  type="warning"
                  class="button"
                  @click="submitForm('ruleForm')"
                  >提交</el-button
                >
              </el-form-item>
            </el-form>
            <!-- <div class="introduce">
                            <div class="introduceItem">
                                <span class="colorFFF">联系方式：</span>
                                <span class="colorF5C">020-87538546</span>
                            </div>
                            <div class="introduceItem">
                                <span class="colorFFF">公司地址：</span>
                                <span class="colorF5C">广州番禺万达互联网创新集聚区</span>
                            </div>
                        </div> -->
          </div>
          <div class="left">联系我们</div>
          <div
            class="line"
            :style="{ top: top + 'px', height: height + 'px' }"
          ></div>
        </div>
      </div>
      <div class="streamline">
        <img src="../../images/streamline.png" alt="" />
      </div>
      <div class="circular">
        <img src="../../images/aboutIcon.png" alt="圆" />
      </div>
      <div class="circular1">
        <img src="../../images/aboutIcon.png" alt="圆" />
      </div>
      <div class="circular2">
        <img src="../../images/aboutIcon.png" alt="圆" />
      </div>
      <div class="circular3">
        <img src="../../images/aboutIcon.png" alt="圆" />
      </div>
      <div class="circular4">
        <img src="../../images/aboutIcon.png" alt="圆" />
      </div>
      <!-- <img src="../../images/aboutBackground.jpg" /> -->
    </div>
    <div id="map">
      <div id="panel"></div>
      <!-- <img src="../../images/map.jpg" alt="地图"> -->
    </div>
  </div>
</template>
<script>
import { insert } from '@/api/about'
import { Message } from 'element-ui'
export default {
  data() {
    return {
      ruleForm: {
        name: '',
        wechat: '',
        phone: '',
        demand: '',
      },
      rules: {
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入联系号码', trigger: 'blur' },
          { min: 8, max: 11, message: '长度在 8 到11位数字', trigger: 'blur' },
        ],
      },
      top: 90,
      height: 42,
      startLngLat: [],
    }
  },
  mounted() {
    this.createMap()
  },
  methods: {
    createMap() {
      let map = new AMap.Map('map', {
        zoom: 17, //级别
        resizeEnable: true,
        center: [113.348981, 23.007436], //中心点坐标
        viewMode: '3D', //使用3D视图
      })
      // let driving = new AMap.Driving();
      let that = this

      // console.log(driving);
      var endIcon = new AMap.Icon({
        size: new AMap.Size(120, 80),
        image: 'http://www.h5-bus.com/images/mapMaker.png',
        imageSize: new AMap.Size(120, 80),
        imageOffset: new AMap.Pixel(0, 0),
      })
      var marker = new AMap.Marker({
        icon: endIcon,
        position: [113.348981, 23.007436],
      })
      marker.on('click', (e) => {
        console.log(e)
        // console.log(driving);
        AMap.plugin(['AMap.Geolocation', 'AMap.Driving'], function() {
          var geolocation = new AMap.Geolocation({
            // 是否使用高精度定位，默认：true
            enableHighAccuracy: true,
            // 设置定位超时时间，默认：无穷大
            timeout: 30000,
            // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
            buttonOffset: new AMap.Pixel(10, 20),
            //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
            zoomToAccuracy: false,
            //  定位按钮的排放位置,  RB表示右下
            buttonPosition: 'RB',
          })
          console.log(geolocation)
          // debugger
          map.addControl(geolocation)
          geolocation.getCurrentPosition()
          AMap.event.addListener(geolocation, 'complete', onComplete)
          AMap.event.addListener(geolocation, 'error', onError)

          function onComplete(data) {
            console.log(data, 'data是具体的定位信息')
            // data是具体的定位信息
            var str = []
            str.push(data.position)
            str.push(data.location_type)
            that.startLngLat = {
              lng: data.position.lng,
              lat: data.position.lat,
            }
            var driving = new AMap.Driving({
              // 驾车路线规划策略，AMap.DrivingPolicy.LEAST_TIME是最快捷模式
              policy: AMap.DrivingPolicy.LEAST_TIME,
              map: map,
              panel: 'panel',
            })

            var startLngLat = [data.position.lng, data.position.lat]
            var endLngLat = [113.348908, 23.006387]

            driving.search(startLngLat, endLngLat, function(status, result) {
              console.log(status)
              if (status === 'complete') {
                console.log('绘制驾车路线完成')
              } else {
                console.log('获取驾车数据失败：' + result)
              }
              // 未出错时，result即是对应的路线规划方案
            })
          }

          function onError(data) {
            // 定位出错
            console.log(data, '定位出错')
          }
        })
      })
      map.add(marker)
      // debugger

      // console.log(map)
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            this.$loading.show('正在提交请稍后')
            let res = await insert(this.ruleForm)
            this.$loading.hide()
            Message({
              type: 'success',
              message: res.data,
            })
          } catch (error) {
            this.$loading.hide()
            console.log(error)
          }

          // alert('submit!');
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    focus(e) {
      console.log(e)
      let top,
        height = 42

      if (e == 1) {
        top = 90
      } else if (e == 2) {
        top = 150
      } else if (e == 3) {
        top = 210
      } else if (e == 4) {
        top = 270
        height = 80
      }
      this.top = top
      this.height = height
    },
  },
}
</script>
<style lang="scss">
.right {
  // .el-form-item{
  //     margin: 0;
  // }
  .el-form-item__label {
    color: #373737;
    padding: 0;
    line-height: normal;
    font-size: 18px;
  }
  .el-form-item__content {
    color: #f5c02f;
    text-align: left;
    line-height: 24px;
    font-weight: 600;
    font-size: 18px;

    // margin-left: 85px;
  }

  .inputBox {
    .el-form-item__content {
      margin: 0 !important;
    }
    .el-textarea {
      width: 400px !important;
    }
    .input {
      .el-input__inner {
        background-color: transparent;
        border-radius: initial;
        border: 1px solid #d5d5d5;
        padding: 9px;
        height: 38px;
        color: #373737;
      }
      .el-textarea__inner {
        background-color: transparent;
        border-radius: initial;
        border: 1px solid #d5d5d5;
        padding: 9px;
        color: #373737;
      }
      .el-input__count {
        color: #373737;
        background-color: transparent;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.warpBox {
  width: 100%;
  overflow: hidden;
  .banner {
    overflow: hidden;

    img {
      transition: all 0.5s;
    }
    img:hover {
      transform: scale(1.05);
    }
  }
  #panel {
    position: absolute;
    background-color: white;
    max-height: 90%;
    overflow-y: auto;
    top: 10px;
    left: 10px;
    width: 280px;
    z-index: 1;
  }
  #panel .amap-call {
    background-color: #009cf9;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
  #panel .amap-lib-driving {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: hidden;
  }
  #map {
    width: 76%;
    margin: 50px auto;
    // padding: 50px 0;
    height: 900px;
  }
  .content {
    position: relative;

    .streamline {
      width: 600px;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .circular {
      height: 80px;
      width: 80px;
      position: absolute;
      left: 60%;
      top: 25px;
    }
    .circular1 {
      width: 16px;
      height: 16px;
      position: absolute;
      left: 53%;
      top: 167px;
    }
    .circular2 {
      width: 28px;
      height: 28px;
      position: absolute;
      left: 57%;
      top: 280px;
    }
    .circular3 {
      width: 16px;
      height: 16px;
      position: absolute;
      left: 72%;
      top: 288px;
    }
    .circular4 {
      width: 28px;
      height: 28px;
      position: absolute;
      right: 14px;
      top: 155px;
    }
    .contentBox {
      // position: absolute;
      width: 100%;
      height: 100%;
      padding: 65px 0 100px;
      .top {
        position: relative;
        z-index: 11;
        .left {
          width: 60px;
          font-size: 44px;
          line-height: 44px;
          // font-weight: 400;
          color: #373737;
          position: absolute;
          top: 44%;
          transform: translateY(-50%);
          left: -90px;
        }
        .line {
          width: 3px;
          height: 42px;
          background: #373737;
          position: absolute;

          left: -10px;
          transition: all 0.5s;
        }
      }

      .right {
        .input {
          width: 250px;
          font-size: 18px;
        }
        .button {
          background: #f5c02f;
          padding: 2px 25px;
          font-size: 20px;
          font-family: SourceHanSansSC;
          font-weight: bold;
          color: #444445;
          border-radius: 5px;
        }
      }
    }
  }
}
</style>
